<template>
  <div class="customizer d-none d-md-block" :class="{ open: isCustomizerOpen }">
    <!-- Toggler -->
    <!-- customizer-toggle -->
    <b-link class="
        customizer-toggle-halls
        d-flex
        align-items-center
        justify-content-center
      " @click="isCustomizerOpen = !isCustomizerOpen">
      <feather-icon icon="ServerIcon" size="15" class="spinner" />
    </b-link>
    <!-- /Toggler -->

    <!-- Header -->
    <div class="
        customizer-section
        d-flex
        justify-content-between
        align-items-center
      ">
      <div>
        <h4 class="text-uppercase mb-0"></h4>
        <!-- <small>Customize &amp; Preview in Real Time</small> -->
      </div>
      <feather-icon icon="XIcon" size="18" class="cursor-pointer" @click="isCustomizerOpen = !isCustomizerOpen" />
    </div>
    <!-- Header -->
    <!-- FilterIcon -->
    <!-- <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="ps-customizer-area scroll-area"
        > -->
    <b-tabs style="height: 90vh;" class="mt-1  overflow-scroll">
      <b-tab title="مواعيد القاعات">

        <div class="border rounded-lg p-1 mt-2 mx-1 shadow">
          <b-row>
            <b-col cols="6">
              <form-input label="النشاط" v-model="title" />
            </b-col>
            <b-col cols="6">
              <form-datepicker label=" تاريخ البداية" v-model="start_date" placeholder="YY-MM-DD" />
            </b-col>
            <b-col cols="6">
              <form-time id="from_time" label="توقيت البداية" v-model="from_time" placeholder="HH-MM"
                style="transform: translate3d(150px, 38px, 0px) !important" />
            </b-col>
            <b-col cols="6">
              <form-time id="to_time" label="توقيت النهاية" v-model="to_time" placeholder="HH-MM"
                style="transform: translate3d(150px, 38px, 0px) !important" />
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end"><b-button variant="primary" @click="unFilter">إلغاء
                البحث</b-button></b-col>
          </b-row>
        </div>
        <div v-if="loading">
          <b-skeleton-table :rows="4" :columns="4"></b-skeleton-table>
        </div>
        <div v-else class="border rounded-lg p-1 mt-2 mx-1 shadow">
          <b-row class="bg-white">
            <b-col cols="2">
              <h5 class="pt-50 pb-50">القاعة</h5>
            </b-col>
            <b-col cols="4">
              <h5 class="pt-50 pb-50 ">النشاط</h5>
            </b-col>
            <b-col cols="3">
              <h5 class="pt-50 pb-50 px-1">من الساعة</h5>
            </b-col>
            <b-col cols="3">
              <h5 class="pt-50 pb-50 px-1">إلى الساعة</h5>
            </b-col>
          </b-row>
          <b-row v-for="(item, i) in dataTable" class="h-row-100" :key="i"
            :style="i % 2 == 0 ? 'background:#EDEDED' : 'background:#fff'" align-w="center">
            <b-col cols="2" class="d-flex align-items-center p-1">{{ item.hall.code }}</b-col>
            <b-col cols="4" class="d-flex align-items-center">{{ item.hallOccupancy.title }}</b-col>
            <b-col cols="3"
              class="d-flex align-items-center justify-content-center">{{
                $moment(item.to_time, ['h:mm']).locale('ar').format('hh:mm A')
              }}</b-col>
            <b-col cols="3"
              class="d-flex align-items-center justify-content-center">{{
                $moment(item.from_time, ['h:mm']).locale('ar').format('hh:mm A')
              }}</b-col>
          </b-row>
          <alert v-if="dataTable.length == 0 && !loading"></alert>
        </div>
      </b-tab>
      <b-tab active title="حجوزات القاعات">
        <data-table v-if="isCustomizerOpen" ref="estatesTable" :fields="fields" ep="/hall-appointments">
          <template #cell(beneficiarys)="row">
            {{ row.item.beneficiary.first_name + " " + row.item.beneficiary.last_name }}
          </template>
          <template #cell(actions)="row">
            <div>
              <b-button variant="flat-info" title="عرض المزيد من المعلومات" size="sm"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="" v-b-tooltip.hover.v-info.top
                @click="row.toggleDetails" v-b-toggle.collapse-1>
                المزيد
                <feather-icon :icon="
                  row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'
                " />
              </b-button>
            </div>
          </template>
          <template #row-details="row">

            <b-collapse id="collapse-1" class="mt-2">
              <b-row>
                <b-col cols="6">
                  <icon-media icon="ClockIcon" keyText="توقيت البداية"
                    :text="$moment(row.item.from_time, ['h:mm']).locale('ar').format('hh:mm A')"
                    class="mb-1"></icon-media>
                  <icon-media icon="ClockIcon" keyText="توقيت النهاية"
                    :text="$moment(row.item.to_time, ['h:mm']).locale('ar').format('hh:mm A')" class="mb-1"></icon-media>
                  <icon-media icon="CalendarIcon" keyText="تاريخ البداية" :text="row.item.from_date"
                    class="mb-1"></icon-media>
                  <icon-media icon="CalendarIcon" keyText="تاريخ النهاية" :text="row.item.to_date"
                    class="mb-1"></icon-media>

                  <icon-media icon="InfoIcon" keyText="عدد الحضور" :text="row.item.number_contributor"
                    class="mb-1"></icon-media>
                  <icon-media icon="InfoIcon" keyText="عدد أيام الحجز" :text="row.item.number_reservation_days"
                    class="mb-1"></icon-media>
                  <icon-media icon="ClockIcon" keyText="عدد الساعات الجلسة" :text="row.item.session_hours"
                    class="mb-1"></icon-media>
                </b-col>
                <b-col cols="6">
                  <h6 class="text-primary">التفاصيل</h6>
                  <p>
                    {{ row.item.description }}
                  </p>
                  <hr />
                  <div>
                    <h6 class="text-primary">التجهيزات المطلوبة</h6>
                    <p>
                      {{ row.item.assets }}
                    </p>
                  </div>
                </b-col>
                <b-col class="my-1">
                  <h6>الأيام</h6>
                  <b-badge v-for="(item, i) in row.item.working_days" :key="i" class="mr-1" variant="light-primary">{{
                    $moment().day(item).locale("ar").format("dddd")
                  }}</b-badge>
                </b-col>
              </b-row>
            </b-collapse>
          </template>
        </data-table>
      </b-tab>
    </b-tabs>
    <!-- </vue-perfect-scrollbar> -->
  </div>
</template>

<script>
import {
  BLink,
  BFormRadioGroup,
  BFormGroup,
  BFormCheckbox,
  BCol,
  BRow,
  BFormTimepicker,
  BSkeletonTable,
  BButton,
  BTabs,
  BCardText,
  BTab,
  BBadge,
  VBTooltip,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import useAppCustomizer from "./useAppCustomizer";
import FormDatepicker from "@/components/form-datepicker";
import FormTime from "@/components/form-time";
import FormInput from "@/components/form-input";
import Alert from "@/components/alert.vue";
import { debounce } from "lodash";
import DataTable from "@/components/data-table/index";
import IconMedia from "@/components/icon-media.vue";

export default {
  components: {
    // BSV
    BLink,
    DataTable,
    IconMedia,
    BBadge,
    BFormRadioGroup,
    BFormCheckbox,
    BFormGroup,
    BCol,
    BRow,
    VBTooltip,
    BCollapse,
    VBToggle,
    FormDatepicker,
    FormTime,
    BFormTimepicker,
    BButton,
    BTabs,
    BCardText,
    BTab,
    // 3rd party
    vSelect,
    VuePerfectScrollbar,
    FormInput,
    BSkeletonTable,
    Alert,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: "title", label: "الاسم", sortable: true },
        { key: "beneficiarys", label: "اسم المستخدم" },
        { key: "phone_number", label: "رقم التواصل" },
        // { key: "destination", label: "الجهة" },

        { key: "actions", label: "" },
      ],
      dataTable: [],
      start_date: "",
      from_time: "",
      to_time: "",
      title: "",
      loading: false,
    };
  },
  methods: {
    unFilter() {
      this.start_date = "";
      this.from_time = "";
      this.to_time = "";
      this.title = "";
    },
    searsh() {
      this.loading = true;
      this.$axios.get("filter-hall-appointments", {
          params: {
            start_date: this.start_date,
            from_time: this.from_time,
            to_time: this.to_time,
            title: this.title,
          },
        })
        .then((res) => {
          this.dataTable = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  watch: {
    isCustomizerOpen(val) {
      if (val) {
        this.searsh();
      }
    },
    start_date(val) {
      this.searsh();
    },
    from_time(val) {
      this.searsh();
    },
    to_time(val) {
      this.searsh();
    },
    title: debounce(function (value) {
      this.searsh();
    }, 400),
  },

  created() {
  },

  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,
    } = useAppCustomizer();

    if (layoutType.value === "horizontal") {
      // Remove semi-dark skin option in horizontal layout
      const skinSemiDarkIndex = skinOptions.findIndex(
        (s) => s.value === "semi-dark"
      );
      delete skinOptions[skinSemiDarkIndex];

      // Remove menu hidden radio in horizontal layout => As we already have switch for it
      const menuHiddneIndex = navbarTypes.findIndex(
        (t) => t.value === "hidden"
      );
      delete navbarTypes[menuHiddneIndex];
    }

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,

      // Perfect Scrollbar
      perfectScrollbarSettings,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.customizer-section {
  padding: 1.5rem;
  border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.ps-customizer-area {
  height: calc(100% - 83px);
}
</style>

